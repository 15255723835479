import { Col, Divider, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { createRef, useEffect, useState } from "react"
import { SellerOffice } from "../../../../domain/entities/SellerOffice"
import { FormField } from "../../../components/Form/FormField"

export interface ServiceSessionModalProps {
	onConfirm?: (modalData: any) => void
	onCancel?: () => void
	visible: boolean
	data?: Partial<SellerOffice>
}

const SellerOfficeModal = ({ visible, onConfirm, data, onCancel }: ServiceSessionModalProps) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.offices" })
	const formRef = createRef<MainFormRef>()
	const [modalData, setModalData] = useState({
		id: data?.id,
		name: data?.name,
		address: data?.address,
		city: data?.city
	})
	useEffect(() => {
		if (!visible) {
			setModalData({ id: undefined, name: undefined, address: undefined, city: undefined })
		} else {
			setModalData({ id: data?.id, name: data?.name, address: data?.address, city: data?.city })
		}
	}, [visible])
	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("new")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto" }}
			open={visible}
			onOk={async () => {
				try {
					await formRef.current?.validateFields()
					onConfirm && onConfirm(modalData)
				} catch {
					// Invalid fields
				}
			}}
			onCancel={() => onCancel && onCancel()}
			width={600}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				className="pl-4 pb-3"
				initialValues={modalData}
				hideFormButtons
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				ref={formRef}
			>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<FormField.Input key={"name"} label={t("name")} name={"name"} mandatory />
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<FormField.Input key={"city"} label={t("city")} name={"city"} />
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<FormField.Input key={"address"} label={t("address")} name={"address"} />
					</Col>
				</Row>
			</MainForm>
		</Modal>
	)
}

export default observer(SellerOfficeModal)
