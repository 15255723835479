import { AnimatePresence, motion } from "framer-motion"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { Progress, Typography } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import { colors } from "../../assets/styles/appStyles"

interface ProgressIndicatorProps {
	visible: boolean
	title?: string
	progressMessage: string
	status?: UploadProgressStatus
	onClose?: () => void
}
const ProgressIndicator = ({ visible, title, progressMessage, status, onClose }: ProgressIndicatorProps) => {
	const progressMessageCls = () => {
		const statusColor: { [key: string]: string } = {
			[UploadProgressStatus.LOADING]: colors.activity,
			[UploadProgressStatus.SUCCESS]: colors.success,
			[UploadProgressStatus.ERROR]: colors.error
		}

		return status ? statusColor[status] : colors.activity
	}

	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					key={"progressIndicator"}
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -20 }}
					transition={{ duration: 0.5, type: "spring" }}
					className="bg-gray-100 px-4 py-8 rounded-lg h-28 relative mb-2 mt-4"
				>
					<CloseOutlined
						className="absolute p-2 right-1 top-1 hover:text-gray-500 cursor-pointer z-10"
						onClick={() => onClose && onClose()}
					/>
					<div className="flex flex-col relative bottom-4 gap-6 w-full">
						{title && <Typography.Text>{title}</Typography.Text>}
						<div className=" w-full flex flex-col">
							<Typography.Text style={{ color: progressMessageCls() }}>{progressMessage}</Typography.Text>
							<Progress
								className={status === UploadProgressStatus.LOADING ? "animate-pulse" : ""}
								percent={100}
								status={status}
								size="small"
								showInfo={false}
							/>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default observer(ProgressIndicator)
