import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default () => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.offices.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "name",
		title: t("name"),
		dataIndex: "name"
	},
	{
		key: "city",
		title: t("city"),
		dataIndex: "city"
	},
	{
		key: "address",
		title: t("address"),
		dataIndex: "address"
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
