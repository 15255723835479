interface ContructorParams {
	id?: number
	name: string
	city: string
	address: string
	sellerId?: number
}

export class SellerOffice {
	public id?: number
	public name: string
	public city: string
	public address: string
	public sellerId?: number

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
		this.city = params.city
		this.address = params.address
		this.sellerId = params.sellerId
	}
}
