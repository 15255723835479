import { Endpoint } from "./../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"
import { SellerServicesDetailModel } from "../../data/models/SellerServicesDetailModel"
import { SellerServicesModel } from "../../data/models/SellerServicesModel"
import { SellerServiceCalendarModel } from "../../data/models/SellerServiceCalendarModel"
import { ServiceError } from "../../ui/error/ServiceError"
import { ServicePicture } from "../../domain/entities/ServicePicture"
import { ServiceStatus } from "../../domain/enum/serviceStatus.enum"

export class SellerServicesService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getServiceDetail(id: string): Promise<SellerServicesDetailModel> {
		try {
			const response = await this._http.requestNewApi({
				url: `/service/admin/${id}`,
				method: "GET"
			})
			return SellerServicesDetailModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_PICTURES_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getSellerServices(offset: number = 0, limit = 40, status?: string): Promise<SellerServicesModel[]> {
		try {
			const params: { [param: string]: any } = { page: Math.ceil(offset / limit), limit }
			if (status) {
				params.status = status
			}
			const response = await this._http.requestNewApi({
				url: `${Endpoint.SELLER_SERVICES}`,
				method: "GET",
				params
			})
			const data = response.data
			return data.map((sellerService: any) => SellerServicesModel.fromRawJson(sellerService))
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICES_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createSellerService(service: SellerServicesDetailModel): Promise<SellerServicesDetailModel> {
		try {
			const {
				title,
				description,
				hint,
				typeService,
				picture,
				isOnline,
				isPresential,
				price,
				isFoundation,
				weight,
				serviceClusters,
				serviceTranslations,
				endorsedUser,
				countryId,
				highlighted,
				seller,
				crossSelling,
				status,
				askForInformation,
				offices
			} = service

			const data = {
				isFoundation,
				title,
				description,
				hint,
				typeService,
				picture,
				isOnline,
				isPresential,
				published: true,
				price,
				weight,
				serviceClusters:
					serviceClusters?.map((cluster: any) => ({
						clusterId: cluster.id,
						weight: Number(cluster.weight)
					})) ?? [],
				serviceTranslations: serviceTranslations ?? [],
				endorsedUser,
				countryId,
				highlighted,
				seller: { id: seller?.id },
				crossSelling,
				status,
				askForInformation,
				offices
			}
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.SELLER_SERVICES_CREATE,
				data
			})
			return SellerServicesDetailModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createServiceContent(service: SellerServicesDetailModel): Promise<number> {
		try {
			const {
				serviceId: service_id,
				title,
				title_en,
				description,
				description_en,
				typeService: type_service,
				isOnline: is_online,
				isPresential: is_presencial,
				price,
				isFoundation: is_foundation,
				serviceClusters: clusters,
				countryId: country_id,
				highlighted
			} = service
			const owner = service.endorsedUser?.id
			const endorsed_user_id = service.endorsedUser?.id
			const endorsed_name = service.endorsedUser?.name
			const categories = clusters?.map((cluster: any) => ({ id: cluster.id, weight: cluster.weight })) ?? []
			const data = {
				service_id,
				owner,
				is_foundation,
				title,
				title_en,
				description,
				description_en,
				type_service,
				is_online,
				is_presencial,
				price,
				categories,
				endorsed_user_id,
				endorsed_user_name: endorsed_name,
				country_id,
				highlighted
			}

			const response = await this._http.requestNewApi({
				url: `/service`,
				method: "POST",
				data
				// headers: { "Content-Type": "multipart/form-data" }
			})

			return response.data.id
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_CONTENT_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateSellerService(service: SellerServicesDetailModel): Promise<SellerServicesDetailModel> {
		try {
			const {
				serviceId,
				title,
				description,
				hint,
				typeService,
				isOnline,
				isPresential,
				price,
				isFoundation,
				weight,
				serviceClusters,
				serviceTranslations,
				endorsedUser,
				countryId,
				highlighted,
				seller,
				crossSelling,
				status,
				askForInformation,
				offices
			} = service

			const data = {
				id: serviceId,
				serviceId,
				isFoundation,
				title,
				description,
				hint,
				typeService,
				isOnline,
				isPresential,
				price,
				weight,
				serviceClusters:
					serviceClusters?.map((cluster: any) => ({
						clusterId: cluster.id,
						weight: Number(cluster.weight)
					})) ?? [],
				serviceTranslations: serviceTranslations ?? [],
				endorsedUser,
				countryId,
				highlighted,
				seller: { id: seller?.id },
				crossSelling,
				status,
				askForInformation,
				offices
			}
			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.SELLER_SERVICES_UPDATE,
				data
			})
			return SellerServicesDetailModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async uploadServiceImages(serviceId: string, files: FormData): Promise<ServicePicture[]> {
		try {
			const response = await this._http.requestNewApi({
				url: `/service/${serviceId}/pictures`,
				method: "POST",
				data: files,
				headers: { "Content-Type": "multipart/form-data" }
			})
			return response.data
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_UPLOAD_IMAGE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteSellerService(id: number, status: ServiceStatus): Promise<void> {
		try {
			await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.SELLER_SERVICES_UPDATE,
				data: { id, status }
			})
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async searchSellerServices(
		expression: string,
		offset: number = 0,
		limit = 100
	): Promise<SellerServicesModel[]> {
		try {
			const response = await this._http.requestNewApi({
				url: `${Endpoint.SELLER_SERVICES_SEARCH}`,
				method: "GET",
				params: { page: Math.ceil(offset / limit), limit, filter: expression }
			})
			const data = response.data
			return data.map((sellerService: any) => SellerServicesModel.fromRawJson(sellerService))
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_SEARCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getSellerServiceExcel(): Promise<Blob> {
		try {
			const response = await this._http.requestNewApi({
				url: `${Endpoint.SELLER_SERVICES_EXCEL}`,
				method: "GET",
				headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
				responseType: "blob"
			})
			//TODO: serverSide must return the file with the correct headers with "Access-Control-Expose-Headers";
			// const contentType = data.headers['content-type'];
			// const attachment = data.headers['content-disposition'];
			//TODO:
			return response.data
		} catch (error: any) {
			throw new ServiceError({
				errorMessage: ServiceError.SERVICE_EXCEL_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
