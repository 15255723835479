import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { ExportInvoicesRequest } from "./request"
import { ExportInvoicesResponse } from "./response"

export class ExportInvoicesUseCase implements UseCase<ExportInvoicesRequest, ExportInvoicesResponse> {
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec(filter: ExportInvoicesRequest): Promise<ExportInvoicesResponse> {
		return this._repository.exportInvoices(filter)
	}
}
