import { MicrositeSellerAssociation } from "../../domain/entities/MicrositeSellerAssociation"

interface ConstructorParams {
	sellerId: number
	micrositeId?: number | null
	marketplace?: string | null
	feePercentage: number
}

export class MicrositeSellerAssociationModel {
	public sellerId: number
	public micrositeId?: number | null
	public marketplace?: string | null
	public feePercentage: number

	constructor(params: ConstructorParams) {
		this.sellerId = params.sellerId
		this.micrositeId = params.micrositeId
		this.marketplace = params.marketplace
		this.feePercentage = params.feePercentage
	}

	static fromRawJson = (rawJson: any): MicrositeSellerAssociationModel => {
		const { feePercentage, micrositeId, marketplace, sellerId } = rawJson
		return new MicrositeSellerAssociationModel({
			sellerId,
			micrositeId,
			marketplace,
			feePercentage
		})
	}

	static fromDomain = (association: MicrositeSellerAssociation): MicrositeSellerAssociationModel => {
		return new MicrositeSellerAssociationModel({ ...association })
	}
}

declare module "./MicrositeSellerAssociationModel" {
	interface MicrositeSellerAssociationModel {
		toDomain(): MicrositeSellerAssociation
	}
}

MicrositeSellerAssociationModel.prototype.toDomain = function (): MicrositeSellerAssociation {
	const data = this
	return new MicrositeSellerAssociation({ ...data })
}
