import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { UpdateInvoicesStatusRequest } from "./request"
import { UpdateInvoicesStatusResponse } from "./response"

export class UpdateInvoicesStatusUseCase implements UseCase<UpdateInvoicesStatusRequest, UpdateInvoicesStatusResponse> {
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec({ invoiceIds, status }: UpdateInvoicesStatusRequest): Promise<UpdateInvoicesStatusResponse> {
		return this._repository.updateInvoicesStatus(invoiceIds, status)
	}
}
