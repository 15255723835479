interface ConstructorParams {
	sellerId: number
	micrositeId?: number | null
	marketplace?: string | null
	feePercentage: number
}

export class MicrositeSellerAssociation {
	public sellerId: number
	public micrositeId?: number | null
	public marketplace?: string | null
	public feePercentage: number

	constructor(params: ConstructorParams) {
		this.sellerId = params.sellerId
		this.micrositeId = params.micrositeId
		this.marketplace = params.marketplace
		this.feePercentage = params.feePercentage
	}
}
