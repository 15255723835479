import { Button, Col, TimePicker, Row, Space, Typography } from "antd"
import { CalendarAvailability } from "../../../../domain/entities/CalendarAvailability"
import { WeekDays } from "../../../../domain/entities/WeekDays"
import { useTranslation } from "react-i18next"
import { PlusCircleOutlined, CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import React, { useEffect, useState } from "react"
import { CalendarScheduleProps } from "./interface/CalendarScheduleProps"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import useUpdateEffect from "../../../hooks/useUpdateEffect"
import { set } from "mobx"

const CONTAINER_WIDTH = 200
const transformToDayJs = CalendarAvailability.transformToDayJs

export const CalendarSchedule = ({
	values,
	onChange,
	disabled,
	minutesInterval = 15,
	hoursInterval = 1,
	sessionDuration = 0
}: CalendarScheduleProps) => {
	const { t } = useTranslation("weekDays")
	const { t: tSchedule } = useTranslation("calendarSchedule")
	const [calendarDays, setCalendarDays] = useState<{ [key: string]: CalendarAvailability[] }>(values)
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	useUpdateEffect(() => {
		recalculateAllSlotsAgain(values)
	}, [minutesInterval, hoursInterval, sessionDuration])
	useEffect(() => setCalendarDays(values), [values])
	const recalculateAllSlotsAgain = (calendarDays: { [key: string]: CalendarAvailability[] }) => {
		const updatedCalendarDays = Object.keys(calendarDays).reduce((acc, calendarDay) => {
			const { isValid, invalidatedSlots } = viewModel.isNewSlotValid(calendarDays[calendarDay], sessionDuration)
			const updatedSlots = calendarDays[calendarDay].map((val, idx) => {
				const invalidSlot = invalidatedSlots.find(invalidatedSlot => invalidatedSlot.id === val.id)
				return invalidSlot
					? { ...val, error: !isValid, errorMessage: invalidSlot.errorMessage }
					: { ...val, error: false, errorMessage: "" }
			})
			return { ...acc, [calendarDay]: updatedSlots }
		}, {})
		setCalendarDays(updatedCalendarDays)
		onChange && onChange(updatedCalendarDays)
	}

	const recalculateSlots = (
		calendarDay: string,
		rangeValue: CalendarAvailability,
		updatedSlots: CalendarAvailability[]
	) => {
		let updatedCalendarDays = {}
		if (updatedSlots?.length) {
			const { isValid, invalidatedSlots } = viewModel.isNewSlotValid(updatedSlots, sessionDuration)
			updatedSlots = updatedSlots.map((val, idx) => {
				const invalidSlot = invalidatedSlots.find(invalidatedSlot => invalidatedSlot.id === val.id)
				return invalidSlot
					? { ...val, error: !isValid, errorMessage: invalidSlot.errorMessage }
					: { ...val, error: false, errorMessage: "" }
			})
			updatedCalendarDays = { ...calendarDays, [calendarDay]: updatedSlots }
			setCalendarDays(updatedCalendarDays)
		} else {
			updatedCalendarDays = {
				...calendarDays,
				[calendarDay]: calendarDays[calendarDay].map((val, idx) => {
					return val.id === rangeValue.id
						? { ...val, start: transformToDayJs(0, 0), end: transformToDayJs(0, 0) }
						: val
				})
			}
			setCalendarDays(updatedCalendarDays)
		}
		onChange && onChange(updatedCalendarDays)
	}

	return (
		<div>
			<Row gutter={[24, 12]} style={{ display: "flex", flexDirection: "column" }}>
				{Object.values(WeekDays).map((weekDay, idx) => (
					<Space align="start" key={idx}>
						<Col style={{ display: "flex" }}>
							<Typography.Text
								style={{
									fontWeight: "500",
									alignSelf: "center",
									position: "absolute",
									top: 14,
									width: 40
								}}
							>
								{t(WeekDays.toString(weekDay).toLowerCase()).substring(0, 3)}
							</Typography.Text>
						</Col>
						<Col span={24} style={{ width: CONTAINER_WIDTH + 50 }}>
							{calendarDays[weekDay]?.length ? (
								Object.keys(calendarDays).map((calendarDay, idx) => {
									const rangeValues = Object.values(calendarDays)[idx]
									return String(weekDay) === String(calendarDay)
										? rangeValues.map((rangeValue, idx) => (
												<div style={{ display: "flex" }}>
													<div
														style={{ padding: 5, flexDirection: "column", display: "flex" }}
													>
														<TimePicker.RangePicker
															order={false}
															autoFocus={rangeValue.error}
															disabled={disabled}
															style={{
																width: CONTAINER_WIDTH,
																backgroundColor: colors.pressable,
																zIndex: 1
															}}
															showMinute={minutesInterval > 0}
															minuteStep={minutesInterval}
															hourStep={hoursInterval > 0 ? hoursInterval : 1}
															status={rangeValue.error ? "error" : undefined}
															changeOnBlur
															cellRender={(date, info) => {
																// Only render cell for hours/minutes that are after the end of the last time slot
																const hoursMinutesValue = Number(date)
																if (idx === 0)
																	return (
																		<div className="ant-picker-time-panel-cell-inner">
																			{date as any}
																		</div>
																	)
																if (info.subType === "hour") {
																	if (
																		info.range === "start" &&
																		hoursMinutesValue >=
																			rangeValues[idx - 1].end.hour()
																	) {
																		return (
																			<div className="ant-picker-time-panel-cell-inner">
																				{String(hoursMinutesValue).length === 1
																					? `0${hoursMinutesValue}`
																					: hoursMinutesValue}
																			</div>
																		)
																	}
																	if (
																		info.range === "end" &&
																		hoursMinutesValue >=
																			rangeValues[idx].start.hour()
																	) {
																		return (
																			<div className="ant-picker-time-panel-cell-inner">
																				{String(hoursMinutesValue).length === 1
																					? `0${hoursMinutesValue}`
																					: hoursMinutesValue}
																			</div>
																		)
																	}
																}
																if (
																	info.subType === "minute" &&
																	hoursMinutesValue >=
																		rangeValues[idx - 1].end.minute()
																) {
																	return (
																		<div className="ant-picker-time-panel-cell-inner">
																			{String(hoursMinutesValue).length === 1
																				? `0${hoursMinutesValue}`
																				: hoursMinutesValue}
																		</div>
																	)
																}
															}}
															onChange={edittedSlot => {
																if (!edittedSlot?.length) return
																let updatedSlots = calendarDays[calendarDay].map(
																	(val, idx) => {
																		return val.id === rangeValue.id
																			? {
																					...val,
																					start: transformToDayJs(
																						//@ts-ignore
																						edittedSlot[0]?.hour(),
																						edittedSlot[0]?.minute()
																					),
																					end: transformToDayJs(
																						//@ts-ignore
																						edittedSlot[1]?.hour(),
																						edittedSlot[1]?.minute()
																					),
																					error: false
																			  }
																			: val
																	}
																)
																recalculateSlots(calendarDay, rangeValue, updatedSlots)
															}}
															value={[rangeValue.start, rangeValue.end]}
															clearIcon={
																<div style={{ backgroundColor: colors.pressable }}>
																	<CloseCircleOutlined
																		style={{
																			backgroundColor: colors.pressable,
																			fontSize: 18,
																			left: 2,
																			position: "relative"
																		}}
																	/>
																</div>
															}
															format="HH:mm"
															size="large"
															placeholder={[tSchedule("start"), tSchedule("end")]}
														/>
														{rangeValue.error && (
															<Typography.Text style={{ color: colors.error }}>
																{tSchedule(rangeValue.errorMessage || "")}
															</Typography.Text>
														)}
													</div>
													{
														<div
															style={{
																position: "absolute",
																right: -50,
																display: "flex",
																width: 70
															}}
														>
															<Button
																type="text"
																style={{
																	...styles.buttonIcon,
																	zIndex: 1,
																	marginRight: 2
																}}
																onClick={() => {
																	const filteredSlots = calendarDays[
																		calendarDay
																	].filter((_, idx) => rangeValue.id !== _.id)
																	if (filteredSlots.length) {
																		recalculateSlots(
																			calendarDay,
																			rangeValue,
																			filteredSlots
																		)
																	} else {
																		const updatedCalendarDays = {
																			...calendarDays,
																			[calendarDay]: filteredSlots
																		}
																		setCalendarDays(updatedCalendarDays)
																		onChange && onChange(updatedCalendarDays)
																	}
																}}
															>
																<DeleteOutlined
																	style={{ ...styles.icon, left: 2.4, bottom: 1 }}
																/>
															</Button>
															{idx === 0 && (
																// calendarDays[weekDay][calendarDays[weekDay].length - 1].end.hour() !== 0 &&
																<Button
																	type="text"
																	style={{ ...styles.buttonIcon }}
																	onClick={() => {
																		const lastEndHour =
																			calendarDays[weekDay][
																				calendarDays[weekDay].length - 1
																			].end.hour() + 1
																		const updatedCalendarDays = {
																			...calendarDays,
																			[weekDay]: [
																				...calendarDays[weekDay],
																				{
																					id: crypto.randomUUID(),
																					day: weekDay,
																					start: transformToDayJs(
																						lastEndHour,
																						0
																					),
																					end: transformToDayJs(
																						lastEndHour + 1,
																						0
																					)
																				}
																			]
																		}
																		setCalendarDays(updatedCalendarDays)
																		onChange && onChange(updatedCalendarDays)
																	}}
																>
																	<PlusCircleOutlined
																		style={{ ...styles.icon, left: 2.67 }}
																	/>
																</Button>
															)}
														</div>
													}
												</div>
										  ))
										: null
								})
							) : (
								<div
									style={{
										padding: 10,
										width: CONTAINER_WIDTH + 94,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<Typography.Text style={{ color: colors.secondaryText }}>
										{tSchedule("notAvailable")}
									</Typography.Text>
									<Button
										type="text"
										style={{ ...styles.buttonIcon, top: 0 }}
										onClick={() =>
											setCalendarDays({
												...calendarDays,
												[weekDay]: [
													{
														id: crypto.randomUUID(),
														day: weekDay,
														start: transformToDayJs(0, 0),
														end: transformToDayJs(0, 0)
													}
												]
											})
										}
									>
										<PlusCircleOutlined style={{ ...styles.icon, left: 2.67 }} />
									</Button>
								</div>
							)}
						</Col>
					</Space>
				))}
			</Row>
		</div>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	buttonIcon: {
		boxShadow: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 30,
		height: 32,
		top: 10,
		borderRadius: 20
	},
	icon: {
		fontSize: 17,
		position: "relative",
		color: colors.actionButton
	}
}
