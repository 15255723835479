import { ReservationLimitInterval } from "../enum/reservationLimitInterval.enum"
import { CalendarAvailability } from "./CalendarAvailability"
import { CalendarData } from "./CalendarData"
import { SellerOffice } from "./SellerOffice"
import { ServiceSession } from "./ServiceSesssion"

interface ContructorParams {
	id?: number
	reservationLimit?: number | null
	reservationLimitInterval?: ReservationLimitInterval | null
	sellerOffice?: SellerOffice
	sellerOfficeId?: number
	serviceId?: number
	serviceCalendar?: CalendarData
	calendarAvailability?: CalendarAvailability[]
	disabled: boolean
}

export class ServiceOffice {
	public id?: number
	public reservationLimit?: number | null
	public reservationLimitInterval?: ReservationLimitInterval | null
	public sellerOfficeId?: number
	public sellerOffice?: SellerOffice
	public serviceId?: number
	public serviceCalendar?: CalendarData
	public calendarAvailability?: CalendarAvailability[]
	public disabled: boolean

	constructor(params: ContructorParams) {
		this.id = params.id
		this.reservationLimit = params.reservationLimit
		this.reservationLimitInterval = params.reservationLimitInterval
		this.sellerOfficeId = params.sellerOfficeId
		this.sellerOffice = params.sellerOffice
		this.serviceId = params.serviceId
		this.serviceCalendar = params.serviceCalendar
		this.calendarAvailability = params.calendarAvailability
		this.disabled = params.disabled
	}
}
