import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import config from "../../../config/config"
import { GetSellersUseCase } from "./../../../domain/useCases/sellers/getSellers/index"
import { GetSellerServicesUseCase } from "../../../domain/useCases/sellersServices/getSellersServices"
import { action, makeObservable, observable, set, toJS } from "mobx"
import { SellerServiceDetail } from "../../../domain/entities/SellerServiceDetail"
import { Seller } from "../../../domain/entities/Seller"
import { CreateSellerServiceUseCase } from "../../../domain/useCases/sellersServices/createSellerService"
import { CreateServiceContentUseCase } from "../../../domain/useCases/sellersServices/createServiceContent"
import { UpdateSellerServiceStatusUseCase } from "../../../domain/useCases/sellersServices/updateSellerServiceStatus"
import { DeleteServiceSessionUseCase } from "../../../domain/useCases/serviceSessions/deleteSession"
import { UpdateServiceSessionUseCase } from "../../../domain/useCases/serviceSessions/updateSession"
import { UploadMultipleSellerServicePictureUseCase } from "../../../domain/useCases/sellersServices/uploadMultipleSellerServicePicturesUseCase"
import { CreateServiceSessionUseCase } from "../../../domain/useCases/serviceSessions/createSession"
import { GetSellerServiceDetailUseCase } from "../../../domain/useCases/sellersServices/getSellerServiceDetail"
import { UpdateSellerServiceUseCase } from "../../../domain/useCases/sellersServices/updateSellerService"
import { Currency } from "../../../domain/entities/Currency"
import { SellerService } from "../../../domain/entities/SellerService"
import { SearchSellersServicesUseCase } from "../../../domain/useCases/sellersServices/searchSellerServices"
import { GetSellerServiceExcelUseCase } from "../../../domain/useCases/sellersServices/getSellerServiceExcel"
import { SellerServiceCalendar } from "../../../domain/entities/SellerServiceCalendar"
import { ApiErrorCodes, ErrorHandler } from "../../error/ErrorHandler"
import { FileService } from "../../../domain/services/FileService"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { CalendarAvailability } from "../../../domain/entities/CalendarAvailability"
import { Country } from "../../../domain/entities/Country"
import { GetCountriesUseCase } from "../../../domain/useCases/countries/getCountries"
import { ServicePicture } from "../../../domain/entities/ServicePicture"
import { ServiceStatus } from "../../../domain/enum/serviceStatus.enum"
import { ServiceSession } from "../../../domain/entities/ServiceSesssion"

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)

interface ContructorParams {
	GetSellerServicesUseCase: GetSellerServicesUseCase
	GetSellersUseCase: GetSellersUseCase
	CreateSellerServiceUseCase: CreateSellerServiceUseCase
	CreateServiceContentUseCase: CreateServiceContentUseCase
	UpdateSellerServiceUseCase: UpdateSellerServiceUseCase
	UpdateSellerServiceStatusUseCase: UpdateSellerServiceStatusUseCase
	DeleteServiceSessionUseCase: DeleteServiceSessionUseCase
	UpdateServiceSessionUseCase: UpdateServiceSessionUseCase
	CreateServiceSessionUseCase: CreateServiceSessionUseCase
	UploadMultipleSellerServicePictureUseCase: UploadMultipleSellerServicePictureUseCase
	GetSellerServiceDetailUseCase: GetSellerServiceDetailUseCase
	SearchSellersServicesUseCase: SearchSellersServicesUseCase
	GetSellerServiceExcelUseCase: GetSellerServiceExcelUseCase
	GetCountriesUseCase: GetCountriesUseCase
	ErrorHandler: ErrorHandler
}

export class SellerServicesViewModel {
	private _getSellersServicesUseCase
	private _getSellersUseCase
	private _createSellerServiceUseCase
	private _createServiceContentUseCase
	private _updateSellerServiceStatusUseCase
	private _deleteSessionUseCase
	private _updateSessionUseCase
	private _createServiceSessionUseCase
	private _uploadPicturesUseCase
	private _getServiceDetailUseCase
	private _updateSellerServiceUseCase
	private _searchSellersServicesUseCase
	private _getSellerServiceExcelUseCase
	private _getCountriesUseCase
	private _errorHandler
	isLoading: boolean = false
	isLoadingDetail: boolean = false
	currencies: Currency[] = [
		{ id: "1", name: "Euro", symbol: "€", isoCode: "EUR", symbolPosition: "after" },
		// {id: "2", name: "US Dolar", symbol: "$", isoCode: "USD", symbolPosition: "before"},
		// {id: "3", name: "Libra esterlina", symbol: "£", isoCode: "GBP", symbolPosition: "before"},
		{ id: "4", name: "Pesos mexicanos", symbol: "$", isoCode: "MXN", symbolPosition: "before" }
	]
	countries: Country[] = []
	isLoadingSellers: boolean = false
	isFetching: boolean = false
	isFetchingExcel: boolean = false
	isLoadingCalendar: boolean = false
	hasNextPage: boolean = true
	searchedServices: Partial<SellerService>[] = []
	sellersServices: Partial<SellerService>[] = []
	selectedService: Partial<SellerServiceDetail> = {}
	sellers: Seller[] = []
	pagination: number = 0
	limit: number = config.ui.componentsConfig.dataTableRecordsLimit
	searchValue: string = ""
	defaultAgendaHourRange: string = ""
	tableConfig: TableConfig = {
		pageSize: config.ui.componentsConfig.dataTablePageSize,
		sort: { order: "descend", field: "id" }
	}

	constructor({
		GetSellerServiceDetailUseCase,
		UpdateSellerServiceUseCase,
		UploadMultipleSellerServicePictureUseCase,
		CreateServiceSessionUseCase,
		UpdateServiceSessionUseCase,
		GetSellerServicesUseCase,
		GetSellersUseCase,
		GetSellerServiceExcelUseCase,
		CreateSellerServiceUseCase,
		CreateServiceContentUseCase,
		UpdateSellerServiceStatusUseCase: DeleteSellerServiceUseCase,
		DeleteServiceSessionUseCase,
		SearchSellersServicesUseCase,
		GetCountriesUseCase,
		ErrorHandler
	}: ContructorParams) {
		makeObservable(this, {
			searchValue: observable,
			isLoading: observable,
			isLoadingSellers: observable,
			isFetchingExcel: observable,
			isLoadingDetail: observable,
			isLoadingCalendar: observable,
			sellersServices: observable,
			sellers: observable,
			countries: observable,
			pagination: observable,
			setPagination: action,
			isFetching: observable,
			hasNextPage: observable,
			setSellersServices: action,
			selectedService: observable,
			searchedServices: observable,
			setSearchedServices: action,
			setSelectedService: action,
			setLoading: action,
			setIsLoadingCalendar: action,
			setIsFetchingExcel: action,
			setIsLoadingDetail: action,
			setIsLoadingSellers: action,
			setCountries: action,
			setIsFetching: action,
			setHasNextPage: action,
			updateServiceSessionState: action,
			tableConfig: observable,
			setTableConfig: action
		})
		this._getSellersServicesUseCase = GetSellerServicesUseCase
		this._getSellersUseCase = GetSellersUseCase
		this._getServiceDetailUseCase = GetSellerServiceDetailUseCase
		this._createSellerServiceUseCase = CreateSellerServiceUseCase
		this._createServiceContentUseCase = CreateServiceContentUseCase
		this._updateSellerServiceUseCase = UpdateSellerServiceUseCase
		this._updateSellerServiceStatusUseCase = DeleteSellerServiceUseCase
		this._deleteSessionUseCase = DeleteServiceSessionUseCase
		this._updateSessionUseCase = UpdateServiceSessionUseCase
		this._getSellerServiceExcelUseCase = GetSellerServiceExcelUseCase
		this._createServiceSessionUseCase = CreateServiceSessionUseCase
		this._uploadPicturesUseCase = UploadMultipleSellerServicePictureUseCase
		this._searchSellersServicesUseCase = SearchSellersServicesUseCase
		this._getCountriesUseCase = GetCountriesUseCase
		this._errorHandler = ErrorHandler
		this.fetchSellersServices()
	}

	public async fetchSellersServices(params?: { pagination?: number; limit?: number }) {
		this.setLoading(true)
		try {
			const sellersServices = await this._getSellersServicesUseCase.exec({
				pagination: params?.pagination ?? 0,
				limit: params?.limit ?? this.limit
			})
			this.setSellersServices(sellersServices)
			this.setSearchedServices(sellersServices)
			this.setHasNextPage(sellersServices.length > 0)
			this.setPagination(0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchSellersServicesExcel() {
		this.setIsFetchingExcel(true)
		try {
			const excel = await this._getSellerServiceExcelUseCase.exec()
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(excel, `Services_${dateString}.csv`)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetchingExcel(false)
		}
	}

	public async fetchPaginatedSellersServices() {
		this.setIsFetching(true)
		try {
			const sellersServices = await this._getSellersServicesUseCase.exec({
				pagination: this.pagination,
				limit: this.limit
			})
			if (sellersServices.length > 0) {
				const combinedSellerServices = [...sellersServices, ...this.sellersServices]
				this.setSellersServices(combinedSellerServices)
				this.setSearchedServices(combinedSellerServices)
			}
			this.setHasNextPage(sellersServices.length > 0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	public async createService(newService: Partial<SellerServiceDetail>) {
		this.setLoading(true)
		try {
			const createdService = await this._createSellerServiceUseCase.exec({ ...newService, picture: undefined })

			if (newService?.serviceSessions?.length! > 0) {
				const sessions: ServiceSession[] = newService.serviceSessions!.map(s => {
					const createdOffice = createdService.offices?.find(
						o => o.sellerOfficeId === s.serviceOffice?.sellerOfficeId
					)
					return { ...s, serviceOfficeId: createdOffice!.id! }
				})
				const sessionsCreated = await this.createSessions(sessions, createdService.id)
				newService.serviceSessions = newService.serviceSessions?.map((session, idx) => {
					session.id = sessionsCreated[idx].id
					return session
				})
			}

			await this._createServiceContentUseCase.exec({
				...newService,
				id: createdService.id,
				picture: undefined
			})

			const servicePictures = await this.uploadPictures(
				createdService.id,
				newService.pictures,
				newService.picture!
			)
			newService.pictures = servicePictures.filter(p => !p.cover)
			newService.picture = servicePictures.find(p => p.cover)?.url

			this.setSelectedService({ ...newService, id: createdService.id })
			await this.fetchSellersServices()
			return createdService.id
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateService(
		service: Partial<SellerServiceDetail>,
		deletedSessions?: SellerServiceDetail["serviceSessions"]
	) {
		this.setLoading(true)
		try {
			const updatedService = await this._updateSellerServiceUseCase.exec(service)
			const sessions: ServiceSession[] =
				service.serviceSessions?.map(s => {
					const office = updatedService.offices?.find(
						o => o.sellerOfficeId === s.serviceOffice?.sellerOfficeId
					)
					return { ...s, serviceOfficeId: office!.id! }
				}) ?? []
			if (sessions?.some(session => !session.id)) {
				const sessionsToCreate = sessions.filter(session => !session.id)
				const sessionsCreated = await this.createSessions(sessionsToCreate, service.id!)
				service.serviceSessions
					?.filter(session => !session.id)
					.map((session, idx) => {
						session.id = sessionsCreated[idx].id
						return session
					})
			}

			if (deletedSessions?.length) {
				await this.deleteSessions(deletedSessions, service.id!)
			}

			if (sessions?.some(session => session?.updated)) {
				const sessionsToUpdate = sessions?.filter(session => session?.updated)
				await this.updateSessions(sessionsToUpdate, service.id!)
				service.serviceSessions = service.serviceSessions?.map(session => {
					session.updated = false
					return session
				})
			}

			const servicePictures = await this.uploadPictures(service.id!, service.pictures, service.picture!)
			service.pictures = servicePictures.length ? servicePictures.filter(p => !p.cover) : service.pictures
			service.picture = servicePictures.find(p => p.cover)?.url
			this.setSelectedService(service)
			this.fetchSellersServices()
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async searchServices(expression: string) {
		if (expression === "") {
			this.setSearchedServices(this.sellersServices)
			return
		}
		this.setLoading(true)
		try {
			const searchedServices = await this._searchSellersServicesUseCase.exec({ expression })
			this.setSearchedServices(searchedServices)
		} catch (error: any) {
			if (error?.errorCode === ApiErrorCodes.SERVICE_NOT_EXISTS) {
				this.setSearchedServices([])
				return
			}
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	private async uploadPictures(serviceId: string, uploadData: any, coverImage: any): Promise<ServicePicture[]> {
		let filesUploaded: ServicePicture[] = []
		let filesToUpload = new FormData()
		uploadData = uploadData?.map((file: any, idx: number) => {
			if (file?.originFileObj && file?.originFileObj instanceof File) {
				file.originFileObj.cover = false
				return file
			}
			return { ...file, cover: false }
		})
		try {
			for (let i = 0; i < uploadData?.length; i++) {
				const file = uploadData[i]
				if (file?.originFileObj && file?.originFileObj instanceof File) {
					filesToUpload.append("files", file.originFileObj)
				} else {
					filesToUpload.append("persistedImages", JSON.stringify(file))
				}
			}
			if (coverImage instanceof File || coverImage?.originFileObj instanceof File) {
				const file = coverImage?.originFileObj ?? coverImage
				filesToUpload.append("coverImageIdentifier", `${coverImage.name}-${coverImage.size}`)
				filesToUpload.append("files", file)
			} else {
				filesToUpload.append("persistedImages", JSON.stringify({ url: coverImage, cover: true }))
			}

			if (filesToUpload.get("files") || uploadData?.filter((file: ServicePicture) => file.url).length > 0) {
				filesUploaded = await this._uploadPicturesUseCase.exec({ id: serviceId, files: filesToUpload })
			}
			return filesUploaded
		} catch (error) {
			// const { onError } = uploadData.methods
			// onError && onError(error)
			throw this._errorHandler.handleError(error)
		}
	}

	public async updateServiceStatus(serviceId: number, status: ServiceStatus) {
		this.setIsLoadingDetail(true)
		try {
			this.setSearchedServices(this.searchedServices.map(s => (s.id === serviceId ? { ...s, status } : s)))
			await this._updateSellerServiceStatusUseCase.exec({ id: serviceId, status })
			this.setSellersServices(this.sellersServices.map(s => (s.id === serviceId ? { ...s, status } : s)))
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsLoadingDetail(false)
		}
	}

	public async fetchSellers() {
		try {
			this.setIsLoadingSellers(true)
			const sellers = await this._getSellersUseCase.exec()
			this.setSellers(sellers)
		} catch (error) {
			console.warn("@@ERROR_ON_FETCH_SELLERS@@", error)
		}

		this.setIsLoadingSellers(false)
	}

	public async findServiceDetail(serviceId: string) {
		return await this._getServiceDetailUseCase.exec(serviceId)
	}

	public async fetchCountries() {
		const countries = await this._getCountriesUseCase.exec()
		this.setCountries(countries)
	}

	public async fetchServiceDetail(serviceId: string) {
		this.setIsLoadingDetail(true)
		try {
			const serviceDetail = await this._getServiceDetailUseCase.exec(serviceId)
			this.updateServiceSessionState(serviceDetail)
			this.setSelectedService({
				...serviceDetail,
				pictures: serviceDetail.serviceContent?.[0]?.pictures,
				serviceSessions: serviceDetail.serviceSessions.map(s => ({
					...s,
					serviceOffice: serviceDetail.offices?.find(o => o.id === s.serviceOfficeId)
				}))
			})
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsLoadingDetail(false)
		}
	}

	private async createSessions(sessions: SellerServiceDetail["serviceSessions"], serviceId: string) {
		const sessionsCreated = await Promise.all(
			sessions.map(async session => {
				const newSession = await this._createServiceSessionUseCase.exec({ session, serviceId })
				return newSession
			})
		)
		return sessionsCreated
	}

	private async updateSessions(sessions: SellerServiceDetail["serviceSessions"], serviceId: string) {
		await Promise.all(
			sessions.map(async session => {
				await this._updateSessionUseCase.exec({ session, serviceId })
			})
		)
	}

	private async deleteSessions(sessions: SellerServiceDetail["serviceSessions"], serviceId: string) {
		await Promise.all(
			sessions.map(async session => {
				await this._deleteSessionUseCase.exec({ session, serviceId })
			})
		)
	}

	public getHoursAvailability(calendar?: SellerServiceCalendar[]) {
		let hoursAvailability: string[] = []

		calendar?.forEach(day => {
			if (Object.values(day).includes("111")) {
				hoursAvailability = ["100", "010", "001"]
			} else if (Object.values(day).includes("110")) {
				hoursAvailability = ["100", "010"]
			} else if (Object.values(day).includes("101")) {
				hoursAvailability = ["100", "001"]
			} else if (Object.values(day).includes("011")) {
				hoursAvailability = ["010", "001"]
			} else if (Object.values(day).includes("100")) {
				hoursAvailability = ["100"]
			} else if (Object.values(day).includes("010")) {
				hoursAvailability = ["010"]
			} else if (Object.values(day).includes("001")) {
				hoursAvailability = ["001"]
			}
		})
		return hoursAvailability
	}

	public getSelectedDays(calendar?: SellerServiceCalendar[]) {
		let selectedDays: number[] = []
		calendar?.forEach(day => {
			Object.values(day).forEach((value, index) => {
				if (value?.toString()?.indexOf("1") !== -1 || value === "000") {
					selectedDays.push(index + 1)
				}
			})
		})
		return selectedDays.filter(values => values <= 7)
	}

	public isNewSlotValid(slotsSaved: CalendarAvailability[], sessionDuration: number) {
		let isValid = true
		let invalidatedSlots: { id: string; errorMessage: string }[] = []

		slotsSaved.sort((a, b) => {
			if (a.start.isBefore(b.start)) return -1
			if (a.start.isAfter(b.start)) return 1
			return 0
		})

		for (let i = slotsSaved.length - 1; i > 0; i--) {
			// if time start is after time end is invalid..
			if (
				slotsSaved[i].start.isSameOrAfter(slotsSaved[i].end) &&
				(slotsSaved[i].end.hour() !== 0 || slotsSaved[i].end.minute() !== 0)
			) {
				isValid = false
				invalidatedSlots.push({ id: slotsSaved[i].id!, errorMessage: "startAfterEnd" })
				continue
			}
			//if slotssaved fit on hours/minutes steps
			if (sessionDuration) {
				if (slotsSaved[i].end.diff(slotsSaved[i].start, "m") < sessionDuration) {
					isValid = false
					invalidatedSlots.push({ id: slotsSaved[i].id!, errorMessage: "notFitInterval" })
					continue
				}
			}
			// if slots are overlaped
			if (
				slotsSaved[i].start.isBetween(slotsSaved[i - 1].start, slotsSaved[i - 1].end) ||
				slotsSaved[i].start.isSame(slotsSaved[i - 1].start) ||
				slotsSaved[i].end.isBetween(slotsSaved[i - 1].start, slotsSaved[i - 1].end) ||
				slotsSaved[i].end.isSame(slotsSaved[i - 1].end)
			) {
				isValid = false
				invalidatedSlots.push({ id: slotsSaved[i].id!, errorMessage: "overlapedSlot" })
				continue
			}
		}
		if (slotsSaved.length === 1) {
			// if time start is after time end is invalid.
			if (
				slotsSaved[0].start.isSameOrAfter(slotsSaved[0].end) &&
				(slotsSaved[0].end.hour() !== 0 || slotsSaved[0].end.minute() !== 0)
			) {
				isValid = false
				invalidatedSlots.push({ id: slotsSaved[0].id!, errorMessage: "startAfterEnd" })
			}
			//if slotssaved fit on hours/minutes steps
			if (sessionDuration) {
				if (slotsSaved[0].end.diff(slotsSaved[0].start, "m") < sessionDuration) {
					isValid = false
					invalidatedSlots.push({ id: slotsSaved[0].id!, errorMessage: "notFitInterval" })
				}
			}
		}
		return { isValid, invalidatedSlots }
	}

	setSellersServices(sellers: Partial<SellerService>[]) {
		this.sellersServices = sellers
	}

	setSellers(sellers: Seller[]) {
		this.sellers = sellers
	}

	setLoading(loading: boolean) {
		this.isLoading = loading
	}

	setIsLoadingDetail(loading: boolean) {
		this.isLoadingDetail = loading
	}

	setIsLoadingSellers(loading: boolean) {
		this.isLoadingSellers = loading
	}

	setIsFetching(loading: boolean) {
		this.isFetching = loading
	}

	setIsLoadingCalendar(loading: boolean) {
		this.isLoadingCalendar = loading
	}

	updateServiceSessionState(service: Partial<SellerServiceDetail>) {
		const sellerServices = this.sellersServices.filter(serv => serv.id !== service.id)
		const sellerService = this.sellersServices.find(serv => String(serv.id) === String(service.id))
		if (sellerService) {
			this.setSellersServices([sellerService, ...sellerServices])
		}
	}

	setPagination(pagination: number) {
		this.pagination = pagination
	}

	setSelectedService(service: Partial<SellerServiceDetail>) {
		this.selectedService = service
	}

	setSearchedServices(services: Partial<SellerService>[]) {
		this.searchedServices = services
	}

	setHasNextPage(hasNextPage: boolean) {
		this.hasNextPage = hasNextPage
	}

	setLimit(limit: number) {
		this.limit = limit
	}

	setDefaultAgendaHourRange(range: string) {
		this.defaultAgendaHourRange = range
	}

	setIsFetchingExcel(loading: boolean) {
		this.isFetchingExcel = loading
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setCountries(countries: Country[]) {
		this.countries = countries
	}
}
