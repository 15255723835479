import { ServiceOffice } from "../../domain/entities/ServiceOffice"
import { ReservationLimitInterval } from "../../domain/enum/reservationLimitInterval.enum"
import { CalendarAvailabilityModel } from "./CalendarAvailabilityModel"
import { CalendarDataModel } from "./CalendarDataModel"
import { SellerOfficeModel } from "./SellerOfficeModel"

interface ContructorParams {
	id?: number
	reservationLimit?: number | null
	reservationLimitInterval?: ReservationLimitInterval | null
	sellerOffice?: SellerOfficeModel
	sellerOfficeId?: number
	serviceId?: number
	serviceCalendar?: CalendarDataModel
	availability?: CalendarAvailabilityModel[]
	disabled: boolean
}

export class ServiceOfficeModel {
	public id?: number
	public reservationLimit?: number | null
	public reservationLimitInterval?: ReservationLimitInterval | null
	public sellerOfficeId?: number
	public sellerOffice?: SellerOfficeModel
	public serviceId?: number
	public disabled: boolean
	public serviceCalendar?: CalendarDataModel
	public availability?: CalendarAvailabilityModel[]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.reservationLimit = params.reservationLimit
		this.reservationLimitInterval = params.reservationLimitInterval
		this.sellerOfficeId = params.sellerOfficeId
		this.sellerOffice = params.sellerOffice
		this.serviceId = params.serviceId
		this.serviceCalendar = params.serviceCalendar
		this.availability = params.availability
		this.disabled = params.disabled
	}

	static fromRawJson = (rawJson: any): ServiceOfficeModel => {
		const {
			id,
			reservationLimit,
			reservationLimitInterval,
			sellerOfficeId,
			serviceId,
			disabled,
			serviceCalendar,
			availability,
			sellerOffice
		} = rawJson
		return new ServiceOfficeModel({
			id,
			reservationLimit,
			reservationLimitInterval,
			sellerOfficeId,
			serviceId,
			sellerOffice: sellerOffice ? SellerOfficeModel.fromRawJson(sellerOffice) : undefined,
			serviceCalendar: serviceCalendar ? CalendarDataModel.fromRawJson(serviceCalendar) : undefined,
			availability: availability
				? availability.map((a: any) => CalendarAvailabilityModel.fromRawJson(a))
				: undefined,
			disabled
		})
	}

	static fromDomain = (service: ServiceOffice): ServiceOfficeModel => {
		return new ServiceOfficeModel({
			...service,
			sellerOffice: service.sellerOffice ? SellerOfficeModel.fromDomain(service.sellerOffice) : undefined,
			serviceCalendar: service.serviceCalendar
				? CalendarDataModel.fromDomain(service.serviceCalendar)
				: undefined,
			availability: service.calendarAvailability
				? service.calendarAvailability.map(a => CalendarAvailabilityModel.fromDomain(a))
				: undefined
		})
	}
}

declare module "./ServiceOfficeModel" {
	interface ServiceOfficeModel {
		toDomain(): ServiceOffice
	}
}

ServiceOfficeModel.prototype.toDomain = function (): ServiceOffice {
	const data = this
	return new ServiceOffice({
		...data,
		sellerOffice: data.sellerOffice?.toDomain(),
		serviceCalendar: data.serviceCalendar?.toDomain(),
		calendarAvailability: data.availability?.map(a => a.toDomain())
	})
}
