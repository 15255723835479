import { Button, Col, Row, Typography, Upload } from "antd"
import UsersTable from "./components/UsersTable"
import { useNavigate } from "react-router-dom"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { UsersViewModel } from "./UsersViewModel"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { observer } from "mobx-react"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { User } from "../../../domain/entities/User"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import config from "../../../config/config"
import { Language } from "../../../locale/interfaces/Translation"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { colors } from "../../assets/styles/appStyles"
import LoadingSpinner from "../../assets/icons/loading"
import { UploadOutlined } from "@ant-design/icons"
import ProgressIndicator from "../../components/ProgressIndicator/ProgressIndicator"

const { Title } = Typography

const UsersView = () => {
	const { t } = useTranslation("users")
	const viewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	const navigate = useNavigate()
	const filterFunction = (value: Partial<User>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(value?.email?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1)
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	const initialUser: Partial<User> = {
		name: "",
		userName: "",
		city: "",
		email: "",
		bio: "",
		language: Language.esES,
		goal: "",
		phoneNumber: "",
		password: "",
		birthdate: undefined,
		role: User.roles[0].value,
		status: User.status[0].value
	}

	useUpdateEffect(() => {
		viewModel.fetchPaginatedUsers()
	}, [viewModel.pagination])

	return (
		<>
			<SearchFilterField<User>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.users}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedUsers(value)
				}}
				inputDelay={config.ui.componentsConfig.searchInputDelay}
				onChangeText={value => {
					viewModel.searchValue = value
					viewModel.searchUsers(value)
				}}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div className="flex items-center justify-between">
									<div className="flex items-center">
										<Title style={{ marginTop: 8 }} level={4}>
											{t("cardTitle")}
										</Title>
										<Button
											style={{ marginLeft: "1.5vw" }}
											type="primary"
											onClick={() =>
												navigate(RoutePaths.USERS_ADD_NEW, { state: { user: initialUser } })
											}
										>
											{t("addNew")}
										</Button>
									</div>
									<div
										style={{
											width: 130,
											height: 40,
											display: "flex",
											justifyContent: "center",
											alignItems: "center"
										}}
									>
										<Upload
											accept=".csv"
											showUploadList={false}
											customRequest={async options => {
												try {
													const { file, onSuccess, onError, onProgress } = options
													viewModel.setProgressData({
														active: true,
														message: t("uploading"),
														status: UploadProgressStatus.LOADING,
														file: file as File
													})
													const uploadData = await viewModel.importUsers(file as File)

													viewModel.setProgressData({
														...viewModel.progressData,
														active: true,
														message:
															uploadData.rowNumber && uploadData.rowNumber >= 0
																? `${uploadData.message} (${t("row")}: ${
																		uploadData.rowNumber
																  })`
																: uploadData.message,
														status:
															uploadData.code !== "ok"
																? UploadProgressStatus.ERROR
																: UploadProgressStatus.SUCCESS
													})
													onSuccess && onSuccess({ message: uploadData.message })
												} catch (error) {
													viewModel.setProgressData({
														...viewModel.progressData,
														active: true,
														message: t("uploadingError"),
														status: UploadProgressStatus.ERROR
													})
												}
											}}
										>
											<Button
												icon={
													viewModel.importingUsers ? <LoadingSpinner /> : <UploadOutlined />
												}
												type="link"
												disabled={viewModel.importingUsers}
												style={
													viewModel.importingUsers ? { color: colors.activity } : undefined
												}
											>
												{t("import")}
											</Button>
										</Upload>
									</div>
								</div>
							}
						>
							<div className="px-6">
								<ProgressIndicator
									visible={viewModel.progressData.active}
									status={viewModel.progressData.status}
									title={viewModel.progressData.file?.name}
									progressMessage={viewModel.progressData.message}
									onClose={() => viewModel.closeProgress()}
								/>
							</div>
							<UsersTable
								onChangePage={(page, pageSize, lastPageFetched) => {
									if (viewModel.searchValue) return
									if (page >= lastPageFetched - 1 && viewModel.hasNextPage && !viewModel.isFetching) {
										if (viewModel.pagination > 1 && viewModel.limit !== 200) {
											viewModel.setLimit(200)
											viewModel.setPagination(0)
											return
										}
										viewModel.setPagination(viewModel.pagination + 1)
									}
								}}
								data={viewModel.searchedUsers}
								loading={viewModel.isLoading}
								isFetching={viewModel.isFetching}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(UsersView)
