import { Checkbox, Col, Collapse, Form, Row } from "antd"
import WeekDaysCheckGroup from "./WeekDaysCheckGroup"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { SellerServiceDetail } from "../../../../domain/entities/SellerServiceDetail"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { WeekDays } from "../../../../domain/entities/WeekDays"
import Divider from "../../../styledComponents/CustomDivider/Divider"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { CalendarSchedule } from "./CalendarSchedule"
import { CalendarAvailability } from "../../../../domain/entities/CalendarAvailability"
import { RightOutlined } from "@ant-design/icons"
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { CollapsibleType } from "antd/lib/collapse/CollapsePanel"
import { observer } from "mobx-react"
import { CalendarData } from "../../../../domain/entities/CalendarData"

interface ServiceAvailabilityFormSectionProps {
	calendarSchedule?: CalendarAvailability[]
	calendarData?: CalendarData
	onChange: ({ calendarAvailability }: any) => void
	disabled?: boolean
}

const options: any = (t: TFunction) => [
	{
		label: t("availability.schedule.timeRanges.morning"),
		value: "100"
	},
	{
		label: t("availability.schedule.timeRanges.afternoon"),
		value: "010"
	},
	{
		label: t("availability.schedule.timeRanges.evening"),
		value: "001"
	}
]

const DEFAULT_COLLAPSE_KEYS = ["schedule", "calendar"]

export interface ServiceAvailabilityFormRef {
	closeCollapseMenu: () => void
	enableCollapseMenu: () => void
}

const ServiceAvailabilityFormSection = forwardRef(
	(
		{ calendarSchedule, calendarData, onChange, disabled }: ServiceAvailabilityFormSectionProps,
		ref: Ref<ServiceAvailabilityFormRef>
	) => {
		const { t } = useTranslation("services", { keyPrefix: "form" })
		const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
		const [collapseKeys, setCollapseKeys] = useState<string[] | string>(!disabled ? DEFAULT_COLLAPSE_KEYS : [])
		const [collapsible, setCollapsible] = useState<CollapsibleType | undefined>(disabled ? "disabled" : undefined)

		useImperativeHandle(ref, () => ({
			closeCollapseMenu: () => {
				setCollapseKeys([])
				setCollapsible("disabled")
			},
			enableCollapseMenu: () => {
				setCollapseKeys(DEFAULT_COLLAPSE_KEYS)
				setCollapsible(undefined)
			}
		}))

		useEffect(() => {
			setCollapsible(disabled ? "disabled" : undefined)
			setCollapseKeys(!disabled ? DEFAULT_COLLAPSE_KEYS : [])
		}, [disabled])
		const combineRanges = (selectedRange: CheckboxValueType[]): string => {
			const selectedRangeIndexs = selectedRange
				.map(value => {
					return value.toString().indexOf("1")
				})
				.filter(value => value !== -1)

			const combinedRange = selectedRangeIndexs.reduce((acc, value) => {
				const newAcc = acc.split("")
				newAcc[value] = "1"
				return newAcc.join("")
			}, "000")
			viewModel.setDefaultAgendaHourRange(combinedRange)
			return combinedRange
		}

		const renderTimeZoneComponent = () => {
			if (!Intl.supportedValuesOf("timeZone").length) {
				console.warn("Your browser does not support Intl.supportedValuesOf().")
				return (
					<FormField.Input
						key={"timeZone"}
						mandatory={calendarData?.calendarId ? true : false}
						label={t("availability.timeZone")}
						value={calendarData?.timeZone}
						onChange={timeZone => onChange && onChange({ serviceCalendar: { ...calendarData, timeZone } })}
					/>
				)
			} else {
				return (
					<FormField.SelectSearch
						key={"timeZone"}
						label={t("availability.timeZone").toString()}
						mandatory={calendarData?.calendarId ? true : false}
						placeholder={t("availability.timeZone").toString()}
						value={calendarData?.timeZone}
						options={Intl.supportedValuesOf("timeZone").map((value: string) => ({ value, label: value }))}
						onChange={timeZone =>
							//@ts-ignore
							onChange && onChange({ serviceCalendar: { ...calendarData, timeZone: timeZone.value } })
						}
					/>
				)
			}
		}

		return (
			<>
				<Collapse
					expandIconPosition="right"
					activeKey={collapseKeys}
					expandIcon={({ isActive }) => {
						const animatedClassName = isActive ? "rotate-90" : ""
						return (
							<div className={`absolute top-[3.1rem] transition-all ${animatedClassName}`}>
								{<RightOutlined />}
							</div>
						)
					}}
					onChange={key => setCollapseKeys(key)}
					collapsible={collapsible}
					ghost
					items={[
						{
							key: "calendar",
							label: (
								<div>
									<Divider title="Calendar" disabled={disabled} />
								</div>
							),
							children: (
								<>
									<Row gutter={[24, 0]}>
										<Col xl={12} md={24} xs={24}>
											<FormField.Input
												key={"calendarId"}
												label={t("availability.calendarId")}
												value={calendarData?.calendarId}
												onChange={calendarId =>
													onChange &&
													onChange({ serviceCalendar: { ...calendarData, calendarId } })
												}
											/>
										</Col>
										<Col xl={12} md={24} xs={24}>
											<FormField.Input
												key={"duration"}
												mandatory={calendarData?.calendarId ? true : false}
												label={t("availability.sessionTime")}
												value={calendarData?.duration}
												inputType={InputType.NUMBER}
												onChange={duration =>
													onChange &&
													onChange({ serviceCalendar: { ...calendarData, duration } })
												}
												customFieldValidation={
													calendarData?.duration &&
													(Number(calendarData.duration) < 15 ||
														Number(calendarData.duration) > 1440)
														? {
																status: "error",
																message: t(
																	"availability.validations.sessionTimeValidation"
																).toString()
														  }
														: { status: "", message: "" }
												}
											/>
										</Col>
									</Row>
									<Row gutter={[24, 0]}>
										<Col xl={12} md={24} xs={24}>
											{renderTimeZoneComponent()}
										</Col>
									</Row>
									<Row gutter={[24, 0]}>
										<Col>
											<CalendarSchedule
												disabled={disabled}
												values={CalendarAvailability.transformToCalendarScheduleObject(
													calendarSchedule
												)}
												onChange={values => {
													onChange &&
														onChange({
															calendarAvailability:
																CalendarAvailability.transformToCalendarScheduleArray(
																	values
																)
														})
												}}
												sessionDuration={
													calendarData?.duration ? Number(calendarData.duration) : undefined
												}
												//@ts-ignore
												hoursInterval={
													calendarData?.duration
														? Math.floor(Number(calendarData.duration) / 60)
														: undefined
												}
												//@ts-ignore
												minutesInterval={
													calendarData?.duration &&
													(60 % Number(calendarData?.duration) == 0 ||
														Math.floor(Number(calendarData.duration) % 60) == 0)
														? Math.floor(Number(calendarData.duration) % 60)
														: 15
												}
											/>
										</Col>
									</Row>
								</>
							)
						}
					]}
				/>
			</>
		)
	}
)

export default observer(ServiceAvailabilityFormSection)
