import { Button, Card } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import DataTable from "../../../components/DataTable/DataTable"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellersViewModel } from "../SellersViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import OFFICE_TABLE_COLUMNS from "../const/OFFICE_TABLE_COLUMNS"
import SellerOfficeModal from "./SellerOfficeModal"
import { useState } from "react"
import { SellerOffice } from "../../../../domain/entities/SellerOffice"
import { DeleteFilled, EditFilled } from "@ant-design/icons"

interface Props {
	onChange?: (value: SellerOffice, position?: number) => void
	onDelete?: (position: number) => void
	offices?: SellerOffice[]
}

const SellerOfficesFormSection = ({ onChange, onDelete, offices }: Props) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.offices" })
	const viewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const [modalVisible, setModalVisible] = useState(false)
	const [modalData, setModalData] = useState<(SellerOffice & { position?: number }) | undefined>(undefined)
	const handleModalConfirm = (values: { name: string; city: string; address: string }) => {
		onChange && onChange(values, modalData?.position)
		setModalVisible(false)
	}
	const handleEditOffice = (office: SellerOffice, position: number) => {
		setModalData({ ...office, position })
		setModalVisible(true)
	}
	const handleDeleteOffice = (position: number) => {
		onDelete && onDelete(position)
	}
	return (
		<>
			<SellerOfficeModal
				data={modalData}
				visible={modalVisible}
				onConfirm={handleModalConfirm}
				onCancel={() => {
					setModalVisible(false)
				}}
			/>
			<Card
				bordered={false}
				className="criclebox tablespace mb-24"
				title={
					<div style={{ display: "flex", alignItems: "center" }}>
						<h6 className="font-semibold m-0">{t("title")}</h6>
						<Button
							onClick={() => {
								setModalData(undefined)
								setModalVisible(true)
							}}
							type="primary"
							style={{
								marginLeft: "1.5vw",
								height: "34px",
								display: "flex",
								alignItems: "center"
							}}
						>
							{t("new")}
						</Button>
					</div>
				}
			>
				<DataTable
					columns={OFFICE_TABLE_COLUMNS()}
					dataSource={
						offices?.map((o, idx) => ({
							id: o.id,
							name: o.name,
							city: o.city,
							address: o.address,
							actions: (
								<div style={{ columnGap: 10 }}>
									<DeleteFilled
										style={{ fontSize: "1.5rem", color: "red" }}
										onClick={() => handleDeleteOffice(idx)}
									/>
									<EditFilled
										style={{ fontSize: "1.5rem", color: "gray" }}
										onClick={() => handleEditOffice(o, idx)}
									/>
								</div>
							)
						})) ?? []
					}
				/>
			</Card>
		</>
	)
}

export default observer(SellerOfficesFormSection)
