import { Invoice } from "../../domain/entities/Invoice"
import { Transaction } from "../../domain/entities/Transaction"
import { InvoiceStatus } from "../../domain/enum/invoiceStatus.enum"
import { SellersModel } from "./SellersModel"

interface ConstructorParams {
	id: number
	invoiceNumber: string
	createdAt: Date
	paymentDate?: Date | null
	emissionDate?: Date | null
	period: string
	sellerId: number
	status: InvoiceStatus
	total?: number
	transactions?: Transaction[]
	seller?: SellersModel
}

export class InvoiceModel {
	public id: number
	public invoiceNumber: string
	public createdAt: Date
	public paymentDate?: Date | null
	public emissionDate?: Date | null
	public period: string
	public sellerId: number
	public status: InvoiceStatus
	public total?: number
	public transactions?: Transaction[]
	public seller?: SellersModel

	public constructor(invoice: ConstructorParams) {
		this.id = invoice.id
		this.invoiceNumber = invoice.invoiceNumber
		this.createdAt = invoice.createdAt
		this.paymentDate = invoice.paymentDate
		this.emissionDate = invoice.emissionDate
		this.period = invoice.period
		this.sellerId = invoice.sellerId
		this.status = invoice.status
		this.total = invoice.total
		this.transactions = invoice.transactions
		this.seller = invoice.seller
	}

	static fromRawJson = (rawJson: any): InvoiceModel => {
		return new InvoiceModel({
			...rawJson,
			seller: rawJson.seller ? SellersModel.fromRawJson(rawJson.seller) : undefined
		})
	}

	static fromDomain = (invoice: Invoice): InvoiceModel => {
		return new InvoiceModel({
			...invoice,
			seller: invoice.seller ? SellersModel.fromDomain(invoice.seller) : undefined
		})
	}
}

declare module "./InvoiceModel" {
	interface InvoiceModel {
		toDomain(): Invoice
	}
}

InvoiceModel.prototype.toDomain = function (): Invoice {
	const data = this
	return new Invoice({
		...data,
		seller: data?.seller?.toDomain()
	})
}
