import { Invoice } from "../../domain/entities/Invoice"
import { InvoiceStatus } from "../../domain/enum/invoiceStatus.enum"
import { BillingRepository } from "../../domain/repositories/billingRepository"
import type { InvoicesFilter } from "../../domain/types/billing/InvoicesFilter"
import { BillingService } from "../services/BillingService"

export class BillingRepositoryImpl implements BillingRepository {
	private billingService: BillingService
	constructor({ BillingService }: { BillingService: BillingService }) {
		this.billingService = BillingService
	}
	updateInvoicesStatus(invoiceIds: number[], status: InvoiceStatus): Promise<void> {
		return this.billingService.updateInvoicesStatus(invoiceIds, status)
	}

	exportInvoices(filter: InvoicesFilter): Promise<Blob> {
		return this.billingService.exportInvoices(filter)
	}

	async getInvoices(filter: InvoicesFilter): Promise<Invoice[]> {
		const invoices = await this.billingService.getInvoices(filter)
		return invoices.map(i => i.toDomain())
	}
}
