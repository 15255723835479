import { Col, Form, Row, Space, Switch, Tag } from "antd"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { observer } from "mobx-react"
import { SellerServiceDetail } from "../../../../domain/entities/SellerServiceDetail"
import { CategoriesViewModel } from "../../categories/CategoriesViewModel"
import { Category } from "../../../../domain/entities/Category"
import { FormTextInputProps } from "../../../components/Form/FormTextInput"
import { useTranslation } from "react-i18next"
import ServiceInformationSectionSkeleton from "../../../styledComponents/LoadingSkeletons/ServiceInformationSectionSkeleton"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { DinamicSelectorWeightForm } from "./DinamicSelectorWeightForm"
import { UsersViewModel } from "../../users/UsersViewModel"
import { User } from "../../../../domain/entities/User"
import MultipleImageUploader from "../../../components/MultipleImageUploader/MultipleImageUploader"
import config from "../../../../config/config"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import { useState } from "react"
import { ReservationLimitInterval } from "../../../../domain/enum/reservationLimitInterval.enum"
import { AuthStore } from "../../../stores/AuthStore"

interface Props {
	editMode?: boolean
	data?: {
		serviceClusters?: Partial<Category>[]
		endorsedUser?: { id?: User["id"]; name?: User["name"] }
		hasReservationLimit?: boolean
	}
	onChangeText?: (value: Partial<SellerServiceDetail> | any) => void
	formTextInputProps?: FormTextInputProps
	picture: SellerServiceDetail["picture"] // Used as Cover photo
	pictures: SellerServiceDetail["pictures"] // Used as Service photos
}

const ServiceInformationFormSection = ({
	editMode,
	data,
	onChangeText,
	picture,
	pictures,
	formTextInputProps
}: Props) => {
	const { t } = useTranslation("services", { keyPrefix: "form.serviceInfo" })
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const categoriesViewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")
	const usersViewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	if (viewModel.isLoadingDetail) {
		return <ServiceInformationSectionSkeleton />
	}

	const users = usersViewModel.searchedUsers.length ? usersViewModel.searchedUsers : usersViewModel.users
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"title"}
						label={t("title")}
						name={"title"}
						mandatory
						onChange={(title: string) => onChangeText && onChangeText({ title })}
						{...formTextInputProps}
					/>
					<FormField.Input
						key={"title_en"}
						label={`${t("title")} (EN)`}
						name={"title_en"}
						mandatory
						onChange={(title_en: string) => onChangeText && onChangeText({ title_en })}
						{...formTextInputProps}
					/>
					<FormField.Input
						key={"title_ca"}
						label={`${t("title")} (CA)`}
						name={"title_ca"}
						onChange={(title_ca: string) => onChangeText && onChangeText({ title_ca })}
						{...formTextInputProps}
					/>
					<FormField.Input
						key={"typeService"}
						label={t("type")}
						name={"typeService"}
						onChange={(typeService: string) => onChangeText && onChangeText({ typeService })}
						{...formTextInputProps}
					/>
				</Col>

				<Col span={24} xl={12} md={12} xs={24}>
					<h4>{t("cover")}</h4>
					<MultimediaUploader
						containerStyle={{ width: "250px", height: "161px" }}
						mediaUrl={picture}
						onClean={() => onChangeText && onChangeText({ picture: null })}
						manualUpload
						disabled={formTextInputProps?.disabled}
						label={t("uploadCover")}
						onChange={picture => onChangeText && onChangeText({ picture })}
						sizeLimit={{ width: config.ui.services.cover.width, height: config.ui.services.cover.height }}
					/>
					<h4>{t("serviceImages")}</h4>
					<MultipleImageUploader
						onClean={() => onChangeText && onChangeText({ picture: null })}
						manualUpload
						src={pictures}
						disabled={formTextInputProps?.disabled}
						// onFinish={(file) => onChangeText && onChangeText({ picture: file?.response?.url })}
						onChange={pictures => onChangeText && onChangeText({ pictures })}
						// customRequest={(uploadData) => viewModel.uploadPicture(uploadData)}
						maxCount={config.ui.services.images.qtyLimit}
						sizeLimit={{ width: config.ui.services.images.width, height: config.ui.services.images.height }}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"description"}
						label={t("description")}
						name={"description"}
						inputType={InputType.TEXTAREA}
						mandatory
						onChange={(description: string) => onChangeText && onChangeText({ description })}
						{...formTextInputProps}
					/>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"description_en"}
						label={`${t("description")} (EN)`}
						name={"description_en"}
						inputType={InputType.TEXTAREA}
						mandatory
						onChange={(description_en: string) => onChangeText && onChangeText({ description_en })}
						{...formTextInputProps}
					/>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"description_ca"}
						label={`${t("description")} (CA)`}
						name={"description_ca"}
						inputType={InputType.TEXTAREA}
						onChange={(description_ca: string) => onChangeText && onChangeText({ description_ca })}
						{...formTextInputProps}
					/>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"hint"}
						label={t("hint")}
						name={"hint"}
						inputType={InputType.TEXTAREA}
						onChange={(hint: string) => onChangeText && onChangeText({ hint })}
						{...formTextInputProps}
					/>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"hint_en"}
						label={`${t("hint")} (EN)`}
						name={"hint_en"}
						inputType={InputType.TEXTAREA}
						onChange={(hint_en: string) => onChangeText && onChangeText({ hint_en })}
						{...formTextInputProps}
					/>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"hint_ca"}
						label={`${t("hint")} (CA)`}
						name={"hint_ca"}
						inputType={InputType.TEXTAREA}
						onChange={(hint_ca: string) => onChangeText && onChangeText({ hint_ca })}
						{...formTextInputProps}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24} style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<DinamicSelectorWeightForm<Category>
						label={t("clusterSelectorLabel").toString()}
						mandatory={isAdmin}
						name="cluster"
						onAdd={cluster =>
							onChangeText &&
							onChangeText({
								serviceClusters: data?.serviceClusters?.length
									? [...data.serviceClusters, cluster]
									: [cluster]
							})
						}
						data={categoriesViewModel.categories.filter(
							category => !data?.serviceClusters?.find(cluster => cluster.id === category.id)
						)}
						loading={categoriesViewModel.isLoading}
						disabled={categoriesViewModel.isLoading || !isAdmin}
					/>
					<Space size={[0, 8]} wrap>
						{data?.serviceClusters
							?.sort((a, b) => Number(a.weight) - Number(b.weight))
							.map((cat, idx) => {
								const clusterDetail = categoriesViewModel.categories.find(
									category => category.id === cat.id
								)
								return clusterDetail ? (
									<Tag
										key={cat.id}
										closable
										style={{
											userSelect: "none",
											height: 30,
											display: "flex",
											alignItems: "center"
										}}
										onClose={() =>
											data.serviceClusters &&
											onChangeText &&
											onChangeText({
												serviceClusters: data.serviceClusters.filter(
													(_, index) => index !== idx
												)
											})
										}
									>
										{`${cat.id} - ${clusterDetail?.label} (${cat.weight})`}
									</Tag>
								) : null
							})}
					</Space>
				</Col>
			</Row>
			<Row style={{ gap: 14, padding: "20px 0px" }}>
				<FormField.SelectSearch
					asyncFetchSearchFunction={async searchValue => await usersViewModel.filterUsers(searchValue)}
					key={"users"}
					name="users"
					style={{ width: "200px" }}
					allowClear
					label={t("endorsedUserSelectorLabel").toString()}
					loading={usersViewModel.isLoading}
					disabled={usersViewModel.isLoading || !isAdmin}
					value={
						data?.endorsedUser?.id
							? {
									key: data?.endorsedUser.id,
									value: data?.endorsedUser.id,
									label: `${data?.endorsedUser.id} - ${data?.endorsedUser.name}`
							  }
							: undefined
					}
					placeholder={t("endorsedUserSelectorPlaceholder").toString()}
					options={users.map(user => ({
						value: user.id,
						label: `${user.id} - ${user.name}`,
						key: user.id
					}))}
					onChange={(option: any) =>
						onChangeText &&
						onChangeText({
							endorsedUserId: option?.value ?? undefined,
							endorsedName: option?.label.split(" - ")[1] ?? ""
						})
					}
				/>
				<FormField.Input
					style={{ marginTop: 40 }}
					mandatory={!!data?.endorsedUser?.id}
					key={"endorsedName"}
					placeHolder={t("endorsedNamePlaceholder").toString()}
					name={"endorsedName"}
					onChange={(endorsedName: string) => onChangeText && onChangeText({ endorsedName })}
					{...formTextInputProps}
					disabled={!isAdmin}
				/>
			</Row>
		</>
	)
}

export default observer(ServiceInformationFormSection)
