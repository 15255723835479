import { Button, Col, Divider, Form, Progress, Row, Typography } from "antd"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"
import { DownloadOutlined } from "@ant-design/icons"
import { colors } from "../../assets/styles/appStyles"
import { useState } from "react"
import { BillingViewModel } from "./BillingViewModel"
import InvoiceTable from "./components/InvoiceTable"
import { CustomBasicSelector as Selector } from "../../components/CustomSelectors/CustomBasicSelector"
import { InvoiceStatus } from "../../../domain/enum/invoiceStatus.enum"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { InvoicesFilter } from "../../../domain/types/billing/InvoicesFilter"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import InvoiceSearchFilter from "./components/InvoiceSearchFilter"

const { Title } = Typography

const BillingView = () => {
	const { t } = useTranslation("billing")
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const [filter, setFilter] = useState<InvoicesFilter>({ offset: 0, limit: viewModel.limit })

	useUpdateEffect(() => {
		viewModel.fetchInvoices(filter)
	}, [filter])

	return (
		<>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div className="flex items-center justify-between">
									<Title className="mt-3" level={4}>
										{t("title")}
									</Title>
									<div className="flex items-center gap-16">
										<div className="flex">
											<Selector
												label={t("status").toString()}
												labelStyle={{ fontWeight: "normal", fontSize: 16 }}
												disabled={viewModel.isLoading}
												popupMatchSelectWidth={false}
												options={
													Object.values(InvoiceStatus).map(status => ({
														label: t(status),
														value: status
													})) ?? []
												}
												maxTagCount="responsive"
												onChange={(value: InvoiceStatus) => viewModel.setSelectedStatus(value)}
												value={viewModel.selectedStatus}
											/>
											<Button
												className="ml-2"
												type="primary"
												onClick={() => viewModel.updateInvoicesStatus()}
											>
												{t("updateSelected")}
											</Button>
										</div>
										<Button
											icon={<DownloadOutlined />}
											type="link"
											disabled={viewModel.isLoading}
											style={viewModel.isLoading ? { color: colors.activity } : undefined}
											onClick={() => viewModel.exportInvoices(filter)}
										>
											{t("export")}
										</Button>
									</div>
								</div>
							}
						>
							<div className="flex flex-column pt-4 items-center">
								<InvoiceSearchFilter onChange={values => setFilter({ ...filter, ...values })} />
								<Progress
									className={viewModel.isLoading ? "animate-pulse" : "invisible"}
									percent={100}
									status={UploadProgressStatus.LOADING}
									size="small"
									showInfo={false}
								/>
								<Divider className="m-0" />
							</div>
							<InvoiceTable
								data={viewModel.invoices}
								loading={viewModel.isLoading}
								onChangePage={(page, pageSize, lastPageFetched) => {
									if (page >= lastPageFetched - 1 && viewModel.hasNextPage && !viewModel.isFetching) {
										if (viewModel.pagination > 1 && viewModel.limit !== 200) {
											viewModel.setLimit(200)
											viewModel.setPagination(0)
											return
										}
										viewModel.setPagination(viewModel.pagination + 1)
									}
								}}
								isFetching={viewModel.isFetching}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(BillingView)
