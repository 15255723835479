import { SellerOffice } from "../../domain/entities/SellerOffice"

interface ContructorParams {
	id?: number
	name: string
	city: string
	address: string
	sellerId?: number
}

export class SellerOfficeModel {
	public id?: number
	public name: string
	public city: string
	public address: string
	public sellerId?: number

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
		this.city = params.city
		this.address = params.address
		this.sellerId = params.sellerId
	}

	static fromRawJson = (rawJson: any): SellerOfficeModel => {
		const { id, name, city, address, sellerId } = rawJson
		return new SellerOfficeModel({
			id,
			name,
			city,
			address,
			sellerId
		})
	}

	static fromDomain = (service: SellerOffice): SellerOfficeModel => {
		return new SellerOfficeModel({ ...service })
	}
}

declare module "./SellerOfficeModel" {
	interface SellerOfficeModel {
		toDomain(): SellerOffice
	}
}

SellerOfficeModel.prototype.toDomain = function (): SellerOffice {
	const data = this
	return new SellerOffice({ ...data })
}
