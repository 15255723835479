import { SellerService } from "../../domain/entities/SellerService"
import { ServiceStatus } from "../../domain/enum/serviceStatus.enum"

interface ContructorParams {
	contact: string
	contact_telf: string
	email: string
	id: number
	name: string
	title: string
	weight: number
	status: ServiceStatus
}

export class SellerServicesModel {
	public contact: string
	public contact_telf: string
	public email: string
	public id: number
	public weight: number
	public name: string
	public title: string
	public status: ServiceStatus

	constructor(params: ContructorParams) {
		this.contact = params.contact
		this.contact_telf = params.contact_telf
		this.email = params.email
		this.id = params.id
		this.name = params.name
		this.title = params.title
		this.weight = params.weight
		this.status = params.status
	}

	static fromRawJson = (rawJson: any): SellerServicesModel => {
		const { id, email, contact_telf, name, title, contact, weight, status } = rawJson
		return new SellerServicesModel({
			id,
			email,
			contact_telf,
			name,
			title,
			weight,
			contact,
			status
		})
	}

	static fromDomain = (service: SellerService): SellerServicesModel => {
		return new SellerServicesModel({
			...service,
			contact: service.contact,
			contact_telf: service.contact_telf,
			email: service.email,
			id: service.id,
			name: service.name,
			title: service.title
		})
	}
}

declare module "./SellerServicesModel" {
	interface SellerServicesModel {
		toDomain(): SellerService
	}
}

SellerServicesModel.prototype.toDomain = function (): SellerService {
	const data = this
	return new SellerService({
		...data,
		contact: data.contact,
		contact_telf: data.contact_telf,
		email: data.email,
		id: data.id,
		name: data.name,
		weight: data.weight,
		title: data.title
	})
}
