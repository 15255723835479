import { File } from "../types/File.type"
import { MicrositeSellerAssociation } from "./MicrositeSellerAssociation"
import { SellerOffice } from "./SellerOffice"

interface ContructorParams {
	id?: string
	userId?: string
	userEmail: string
	sellerName: string
	nif: string
	iban: string
	contact: string
	phone: string
	weekdays?: string | null
	logo?: string
	schedule?: Date
	organization?: string
	description?: string
	expYears?: number
	web?: string
	degrees?: string //string[];
	fiscalName?: string
	fiscalCountry?: string
	feePercentage?: string
	paymentDays?: string
	offices?: SellerOffice[]
	micrositeAssociations?: MicrositeSellerAssociation[]
}

export class Seller {
	public id?: string
	public userId?: string
	public userEmail: string
	public sellerName: string
	public nif: string
	public iban: string
	public contact: string
	public phone: string
	public weekdays?: string | null
	public logo?: string & File
	public schedule?: Date
	public organization?: string
	public description?: string
	public expYears?: number
	public web?: string
	public degrees?: string //string[];
	public fiscalName?: string
	public fiscalCountry?: string
	public feePercentage?: string
	public paymentDays?: string
	public offices?: SellerOffice[]
	public micrositeAssociations?: MicrositeSellerAssociation[]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.userId = params.userId
		this.userEmail = params.userEmail
		this.sellerName = params.sellerName
		this.nif = params.nif
		this.iban = params.iban
		this.contact = params.contact
		this.phone = params.phone
		this.weekdays = params.weekdays
		this.logo = params.logo
		this.schedule = params.schedule
		this.organization = params.organization
		this.description = params.description
		this.expYears = params.expYears
		this.web = params.web
		this.degrees = params.degrees
		this.fiscalName = params.fiscalName
		this.fiscalCountry = params.fiscalCountry
		this.feePercentage = params.feePercentage
		this.paymentDays = params.paymentDays
		this.offices = params.offices
		this.micrositeAssociations = params.micrositeAssociations
	}
}
