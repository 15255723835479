import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { GetInvoicesRequest } from "./request"
import { GetInvoicesResponse } from "./response"

export class GetInvoicesUseCase implements UseCase<GetInvoicesRequest, GetInvoicesResponse> {
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec(filter: GetInvoicesRequest): Promise<GetInvoicesResponse> {
		return this._repository.getInvoices(filter)
	}
}
