import dayjs, { Dayjs } from "dayjs"

interface ContructorParams {
	id: string
	day: number
	start: any
	end: any
	serviceOfficeId: number
}

export class CalendarAvailability {
	public id: string
	public day: number
	public start: Dayjs
	public end: Dayjs
	public error?: boolean
	public errorMessage?: string
	public serviceOfficeId: number

	constructor(params: ContructorParams) {
		this.id = params.id
		this.day = params.day
		this.start = params.start
		this.end = params.end
		this.serviceOfficeId = params.serviceOfficeId
	}

	public static transformToDayJs(hours: number, minutes = 0) {
		if (hours > 24) hours = 24
		const dateTime = dayjs().set("hour", hours).set("minute", minutes).set("second", 0)
		return dateTime
	}

	public static transformToCalendarScheduleObject(calendarSchedule?: CalendarAvailability[]) {
		let calendarScheduleObject: { [key: string]: CalendarAvailability[] } = {}
		if (calendarSchedule) {
			Object.values(calendarSchedule).forEach(slot => {
				const start = dayjs(slot.start)
				const end = dayjs(slot.end)
				if (Object.keys(calendarScheduleObject).findIndex(value => value === String(slot.day)) !== -1) {
					calendarScheduleObject[slot.day].push({
						day: slot.day,
						id: slot.id,
						start,
						end,
						serviceOfficeId: slot.serviceOfficeId
					})
				} else {
					calendarScheduleObject[slot.day] = [
						{ day: slot.day, id: slot.id, start, end, serviceOfficeId: slot.serviceOfficeId }
					]
				}
			})
		}
		return calendarScheduleObject
	}

	public static transformToCalendarScheduleArray(calendarSchedule: { [key: string]: CalendarAvailability[] }) {
		let calendarScheduleArray: CalendarAvailability[] = []
		Object.values(calendarSchedule).forEach(day => {
			day.forEach(slot => {
				calendarScheduleArray.push(slot)
			})
		})

		return calendarScheduleArray
	}

	public static getTimezoneOffset(timeZone: string, date = new Date()) {
		const tz = date.toLocaleString("en", { timeZone, timeStyle: "long" }).split(" ").slice(-1)[0]
		const dateString = date.toString()
		let offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`)

		offset = offset / 60 / 60 / 1000

		// return UTC offset in hours
		return offset > 0 ? `+${offset}` : `${offset}`
	}
}
