import { Endpoint } from "./../network/endpoint"
import { SellersModel } from "../models/SellersModel"
import type { HttpManager } from "../network/interface/HttpManager"
import { UserModel } from "../models/UserModel"
import { SellerError } from "../../ui/error/SellerError"
import { SellersNewModel } from "../models/SellersNewModel"
import { AxiosResponse } from "axios"

export class SellersService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getSeller(id: string): Promise<SellersModel> {
		const data = await this._http.get(`/sellers/${id}`)
		return SellersModel.fromRawJson(data)
	}

	public async getSellers(): Promise<SellersModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.SELLERS
			})
			return response.data.map((seller: SellersModel) => SellersModel.fromRawJson(seller))
		} catch (error: any) {
			throw new SellerError({
				errorMessage: SellerError.SELLERS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getUsersByAutoComplete(searchText: string): Promise<UserModel[]> {
		try {
			const response = await this._http.post(Endpoint.SELLERS_AUTOCOMPLETE, { key: searchText })
			return response.data.map((user: UserModel) => UserModel.fromRawJson(user))
		} catch (error: any) {
			throw new SellerError({
				errorMessage: SellerError.SELLERS_FETCH_USERS_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createSeller(seller: SellersNewModel): Promise<SellersNewModel> {
		try {
			const data = {
				...seller,
				userId: Number(seller.userId),
				schedule: seller.schedule || null,
				weekdays: seller.weekdays || null,
				organization: seller.organization || null,
				web: seller.web || null,
				degrees: seller.degrees?.length ? seller.degrees : null,
				logo: undefined
			}
			const sellerCreate = await this._http.requestNewApi({
				url: Endpoint.SELLER_CREATE,
				method: "POST",
				data
			})
			let logoUrl: string | undefined
			if (seller.logo) {
				const formData = new FormData()
				formData.append("logo", seller.logo)
				const response = await this._http.requestNewApi({
					url: Endpoint.SELLER_UPDATE_LOGO.replace("{sellerId}", sellerCreate.data.id),
					method: "POST",
					data: formData,
					headers: { "Content-Type": "multipart/form-data" }
				})
				logoUrl = response.data
			}
			return SellersNewModel.fromRawJson({
				...data,
				offices: sellerCreate.data.offices,
				id: sellerCreate.data.id,
				logo: logoUrl
			})
		} catch (error: any) {
			throw new SellerError({
				errorMessage: SellerError.SELLER_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateSeller(seller: SellersNewModel): Promise<SellersNewModel> {
		try {
			const data = {
				...seller,
				schedule: seller.schedule || null,
				weekdays: seller.weekdays || null,
				organization: seller.organization || null,
				web: seller.web || null,
				degrees: seller.degrees?.length ? seller.degrees : null,
				userId: undefined,
				logo: seller.logo ? undefined : null
			}
			if (seller.id) {
				const sellerUpdate = this._http.requestNewApi({
					url: Endpoint.SELLER_UPDATE.replace("{sellerId}", seller.id),
					method: "PATCH",
					data: { ...data }
				})
				let logoUpdate: Promise<AxiosResponse | null> = Promise.resolve(null)
				if (seller.logo && typeof seller.logo !== "string") {
					const formData = new FormData()
					formData.append("logo", seller.logo)
					logoUpdate = this._http.requestNewApi({
						url: Endpoint.SELLER_UPDATE_LOGO.replace("{sellerId}", seller.id),
						method: "POST",
						data: formData,
						headers: { "Content-Type": "multipart/form-data" }
					})
				}
				const [sellerResponse, logoResponse] = await Promise.all([sellerUpdate, logoUpdate])
				return SellersNewModel.fromRawJson({
					...data,
					offices: sellerResponse.data.offices,
					logo: logoResponse?.data ?? seller.logo
				})
			} else {
				throw new SellerError({ errorMessage: SellerError.SELLER_UPDATE_ERROR, httpStatus: 400 })
			}
		} catch (error: any) {
			throw new SellerError({
				errorMessage: error?.message ?? SellerError.SELLER_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
