import { Form, Space } from "antd"
import { observer } from "mobx-react"
import { InvoiceStatus } from "../../../../domain/enum/invoiceStatus.enum"
import { CustomBasicSelector as Selector } from "../../../components/CustomSelectors/CustomBasicSelector"
import { InvoicesFilter } from "../../../../domain/types/billing/InvoicesFilter"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { BillingViewModel } from "../BillingViewModel"
import { SellersViewModel } from "../../sellers/SellersViewModel"
import { useTranslation } from "react-i18next"

interface InvoiceFilterProps {
	onChange?: (value: Partial<InvoicesFilter>) => void
}
const InvoiceSearchFilter = ({ onChange }: InvoiceFilterProps) => {
	const { t } = useTranslation("billing")
	const [filterForm] = Form.useForm<InvoicesFilter>()
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const sellersViewModel = useContainerInjection<SellersViewModel>("SellersViewModel")

	return (
		<Form form={filterForm} disabled={viewModel.isFetching || viewModel.isLoading}>
			<Space direction="horizontal" align="end" size={"large"} className="flex gap-8">
				<Selector
					disabled={sellersViewModel.isLoading}
					loading={sellersViewModel.isLoading}
					maxTagCount={1}
					style={{ width: "15rem" }}
					mode="multiple"
					allowClear
					label={t("seller")}
					onChange={values => onChange && onChange({ sellerId: values.length > 0 ? values : undefined })}
					options={sellersViewModel.sellers.map(s => ({
						label: `${s.id} - ${s.sellerName}`,
						value: s.id
					}))}
				/>
				<Selector
					allowClear
					label={t("period")}
					options={Array.from(new Set(viewModel.invoices.map(i => i.period))).map(period => ({
						label: period,
						value: period
					}))}
					onChange={value => onChange && onChange({ period: value })}
				/>
				<Selector
					style={{ width: "10rem" }}
					allowClear
					label={t("status")}
					options={
						Object.values(InvoiceStatus).map(status => ({
							label: t(status),
							value: status
						})) ?? []
					}
					onChange={value => onChange && onChange({ status: value })}
				/>
			</Space>
		</Form>
	)
}

export default observer(InvoiceSearchFilter)
