interface ContructorParams {
	calendarId: string | null
	duration: string
	timeZone: string
	serviceOfficeId: number
}

export class CalendarData {
	public calendarId: string | null
	public duration: string
	public timeZone: string
	public serviceOfficeId: number

	constructor(params: ContructorParams) {
		this.calendarId = params.calendarId
		this.duration = params.duration
		this.timeZone = params.timeZone
		this.serviceOfficeId = params.serviceOfficeId
	}
}
