import { action, makeObservable, observable } from "mobx"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { ViewModel } from "../ViewModel"
import { FileService } from "../../../domain/services/FileService"
import { GetInvoicesUseCase } from "../../../domain/useCases/billing/getInvoices"
import { Invoice } from "../../../domain/entities/Invoice"
import { InvoicesFilter } from "../../../domain/types/billing/InvoicesFilter"
import { InvoiceStatus } from "../../../domain/enum/invoiceStatus.enum"
import { ExportInvoicesUseCase } from "../../../domain/useCases/billing/exportInvoices"
import { UpdateInvoicesStatusUseCase } from "../../../domain/useCases/billing/updateInvoicesStatus"

interface Props {
	ErrorHandler: ErrorHandler
	GetInvoicesUseCase: GetInvoicesUseCase
	ExportInvoicesUseCase: ExportInvoicesUseCase
	UpdateInvoicesStatusUseCase: UpdateInvoicesStatusUseCase
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class BillingViewModel extends ViewModel {
	private _errorHandler
	private _getInvoicesUseCase: GetInvoicesUseCase
	private _exportInvoicesUseCase: ExportInvoicesUseCase
	private _updateInvoicesStatusUseCase: UpdateInvoicesStatusUseCase
	isFetching: boolean = false
	limit = 400
	isLoading: boolean = false
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	invoices: Invoice[] = []
	selectedInvoiceIds: number[] = []
	selectedStatus: InvoiceStatus = InvoiceStatus.EMITTED
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	tipTableFilters = {}

	constructor({ ErrorHandler, GetInvoicesUseCase, ExportInvoicesUseCase, UpdateInvoicesStatusUseCase }: Props) {
		super()
		makeObservable(this, {
			isFetching: observable,
			isLoading: observable,
			invoices: observable,
			selectedInvoiceIds: observable,
			selectedStatus: observable,
			setInvoices: action,
			setLoading: action,
			setIsFetching: action,
			setSelectedInvoiceIds: action,
			cleanPagination: action,
			setSelectedStatus: action
		})
		this._errorHandler = ErrorHandler
		this._getInvoicesUseCase = GetInvoicesUseCase
		this._exportInvoicesUseCase = ExportInvoicesUseCase
		this._updateInvoicesStatusUseCase = UpdateInvoicesStatusUseCase
		this.fetchInvoices()
	}

	public async fetchInvoices(filter?: InvoicesFilter) {
		try {
			this.setIsFetching(true)
			const invoices = await this._getInvoicesUseCase.exec({
				offset: 0,
				limit: this.limit,
				...filter
			})
			this.setSelectedInvoiceIds([])
			this.setInvoices(invoices)
			this.cleanPagination()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	public async exportInvoices(filter?: InvoicesFilter) {
		try {
			this.setLoading(true)
			const file = await this._exportInvoicesUseCase.exec({
				offset: 0,
				limit: this.limit,
				...filter
			})
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(file, `Facturación_${dateString}.csv`)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateInvoicesStatus() {
		if ((this.selectedInvoiceIds?.length ?? 0) === 0) return
		try {
			this.setLoading(true)
			await this._updateInvoicesStatusUseCase.exec({
				invoiceIds: this.selectedInvoiceIds,
				status: this.selectedStatus
			})
			const isSelected = this.selectedInvoiceIds.reduce(
				(ids, selected) => ({ ...ids, [selected.toString()]: true }),
				{} as { [key: string]: boolean }
			)
			this.fetchInvoices()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public cleanPagination() {
		this.pagination = 0
		this.setHasNextPage(true)
	}

	// ACTIONS
	setInvoices(invoices: Invoice[]) {
		this.invoices = invoices
	}

	setSelectedInvoiceIds(selectedInvoiceIds: number[]) {
		this.selectedInvoiceIds = selectedInvoiceIds
		console.log(this.selectedInvoiceIds)
	}

	setSelectedStatus(selectedStatus: InvoiceStatus) {
		this.selectedStatus = selectedStatus
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}
}
