import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class BillingError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static INVOICES_GET_ERROR = "There was an error getting the invoices"
	static INVOICES_UPDATE_ERROR = "There was an error updating the invoice"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "BillingError"
	}
}
