import { Col, Row, Space, Tag } from "antd"
import { Seller } from "../../../../domain/entities/Seller"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { FormField } from "../../../components/Form/FormField"
import config from "../../../../config/config"
import { DinamicSelectorWeightForm } from "../../sellersServices/components/DinamicSelectorWeightForm"
import { Microsite } from "../../../../domain/entities/Microsite"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositesViewModel } from "../../microSites/MicrositesViewModel"
import { observer } from "mobx-react"

interface Props {
	seller: Partial<Seller>
	inputDisabled?: boolean
	onChangeText?: (value: Partial<Seller>) => void
}

const AdministrativeInformationFormSection = ({ seller, inputDisabled, onChangeText }: Props) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.fields" })
	const { t: tCountries } = useTranslation("countries")
	const micrositesViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"fiscalName"}
						label={t("fiscalName")}
						name={"fiscalName"}
						onChange={(fiscalName: string) => onChangeText && onChangeText({ fiscalName })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Select
						disabled={inputDisabled}
						mandatory
						key={"fiscalCountry"}
						label={t("fiscalCountry")}
						name={"fiscalCountry"}
						onChange={(fiscalCountry: string) => onChangeText && onChangeText({ fiscalCountry })}
						options={config.tax.fiscalCountries.map(country => ({
							value: country,
							label: tCountries(country),
							key: country
						}))}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						inputType={InputType.NUMBER}
						key={"paymentDays"}
						label={t("paymentDays")}
						name={"paymentDays"}
						mandatory
						onChange={(paymentDays: string) => onChangeText && onChangeText({ paymentDays })}
						style={{ marginBottom: "9px" }}
						customFieldPosition={"relative"}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						inputType={InputType.NUMBER}
						key={"feePercentage"}
						label={t("feePercentage")}
						name={"feePercentage"}
						mandatory
						preFix={"%"}
						onChange={(feePercentage: string) => onChangeText && onChangeText({ feePercentage })}
						style={{ marginBottom: "9px" }}
						customFieldPosition={"relative"}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"nif"}
						label={t("nif")}
						name={"nif"}
						mandatory
						onChange={(nif: string) => onChangeText && onChangeText({ nif })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						disabled={inputDisabled}
						key={"iban"}
						label={t("iban")}
						name={"iban"}
						mandatory
						onChange={(iban: string) => onChangeText && onChangeText({ iban })}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24} style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<DinamicSelectorWeightForm<Microsite>
						label={t("feePercentage")}
						weightLabel={t("feePercentage") + " (%)"}
						name="micrositeAssociations"
						onAdd={microsite =>
							onChangeText &&
							onChangeText({
								micrositeAssociations: (seller.micrositeAssociations ?? []).concat([
									["ES", "MX"].includes(microsite.id.toString())
										? {
												sellerId: Number(seller.id),
												marketplace: microsite.id.toString(),
												feePercentage: Number(microsite.weight)
										  }
										: {
												sellerId: Number(seller.id),
												micrositeId: Number(microsite.id),
												feePercentage: Number(microsite.weight)
										  }
								])
							})
						}
						data={[
							{
								id: "ES" as string | number,
								label: `${t("marketplace")} ES`,
								value: "ES",
								keepLabel: true
							},
							{
								id: "MX" as string | number,
								label: `${t("marketplace")} MX`,
								value: "MX",
								keepLabel: true
							}
						]
							.concat(
								micrositesViewModel.microsites.map(m => ({
									id: m.id!,
									label: m.slug!,
									value: m.id!,
									keepLabel: false
								}))
							)
							.filter(
								m =>
									!seller.micrositeAssociations?.some(
										a =>
											a.micrositeId?.toString() === m.id?.toString() ||
											a.marketplace?.toString() === m.id?.toString()
									)
							)}
						loading={micrositesViewModel.isLoading}
						disabled={micrositesViewModel.isLoading || inputDisabled}
					/>
					<Space size={[0, 8]} wrap>
						{seller.micrositeAssociations?.map((association, idx) => {
							const microsite = micrositesViewModel.microsites.find(
								microsite => microsite.id?.toString() === association.micrositeId?.toString()
							)
							let label = `${microsite?.id} - ${microsite?.slug}`
							if (!microsite) {
								label = `${t("marketplace")} ${association.marketplace}`
							}
							return microsite || association.marketplace ? (
								<Tag
									key={microsite?.id ?? association.marketplace}
									closable
									style={{
										userSelect: "none",
										height: 30,
										display: "flex",
										alignItems: "center"
									}}
									onClose={() =>
										seller.micrositeAssociations &&
										onChangeText &&
										onChangeText({
											micrositeAssociations: seller.micrositeAssociations.filter(
												(_, index) => index !== idx
											)
										})
									}
								>
									{`${label} (${association.feePercentage}%)`}
								</Tag>
							) : null
						})}
					</Space>
				</Col>
			</Row>
		</>
	)
}

export default observer(AdministrativeInformationFormSection)
